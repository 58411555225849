<template>
  <div class="login">
    <h1>Un Poker <span>ce soir ?</span></h1>
    
    <form v-if="etape === 0" action="" @click.prevent="telFormSubmit" >
      <p>Inscrivez-vous en 2 minutes à nos parties de Texax Hold'em Poker</p>
      
      <vue-tel-input v-model="phone" :inputOptions="{ placeholder: 'Numéro de télephone', autofocus: true }" @validate="phone_validate_method"></vue-tel-input>
      
      <button class="min" :data-active="buttonActive">Connexion</button>
    
    
    </form>
    <form v-else-if="etape === 1" @submit.prevent="pinFormSubmit">
      <p>Entrez le code pin reçu par SMS</p>
      <div class="error" v-if="error">{{ error }}</div>
      <v-otp-input
          class="opt"
          ref="otpInput"
          input-classes="otp-input"
          separator=""
          :num-inputs="6"
          :should-auto-focus="true"
          :is-input-num="true"
          @on-change="handleOnChange"
          @on-complete="handleOnComplete"
      />
      <button class="min" :data-active="buttonActive">Continuer</button>
    </form>
    <form v-else-if="etape === 2" class="nameForm" @submit.prevent="infosSubmit">
      <h1>Bienvenue !</h1>
      <div class="error" v-if="error">{{ error }}</div>
      <input type="text" v-model="name" placeholder="Nom">
      <input type="text" v-model="firstName" placeholder="Prénom">
      <input type="email" v-model="email" placeholder="Adresse e-mail">
      <input type="password" v-model="password" placeholder="Mot de passe">
      <button :data-active="buttonActive">C'est parti !</button>
    </form>
    
    
    <div></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      phone: null,
      etape: 0,
      phone_validate: false,
      buttonActive: false,
      opt_validate: false,
      opt_value: null,
      error: null,
      firstName: null,
      name: null,
      email: null,
      password: null,
    }
  },
  methods: {
    handleOnChange(value) {
      console.log('OTP handleOnChange: ', value);
      this.buttonActive = false
      this.opt_validate = false
    },
    handleOnComplete(value) {
      console.log('OTP completed: ', value);
      this.buttonActive = true
      this.opt_validate = true
      this.opt_value = value
      this.pinFormSubmit()
    },
    phone_validate_method(data) {
      this.phone_validate = data
      this.buttonActive = data.valid
    },
    telFormSubmit() {
      if (this.phone_validate && this.phone_validate.valid === true) {
        this.etape = 1
        axios.post('/checksms/create', { phoneNumber: this.phone_validate.number })
        this.buttonActive = false
      }
    },
    pinFormSubmit() {
      if (this.opt_validate) {
        this.buttonActive = false
        axios.post('/checksms/check', { phoneNumber: this.phone_validate.number, phoneKey: this.opt_value, autoLogin: true }).then((r) => {
          if(r.data.next) {
            this.etape = 2
          }
          else if(r.data.token) {
            localStorage.setItem("creative_token", r.data.token);
            this.$store.dispatch('creativeAuth/login', r.data.token)
            this.$router.go(-1)
          }
        }).catch((e) => {
          this.$refs.otpInput.clearInput();
          this.error = e.response.data.error
        })
      }
    },
    infosSubmit() {
      this.error = null
      axios.post('/auth/create', { 
        firstName: this.firstName,
        name: this.name, 
        email: this.email, 
        password: this.password, 
        phone: this.phone_validate.number, 
        projectId: this.$content.projectId
      }).then((r) => {
        if(r.data.token) {
          localStorage.setItem("creative_token", r.data.token);
          this.$store.dispatch('creativeAuth/login', r.data.token)
          this.$router.go(-1)
        }
      }).catch((e) => {
        this.$refs.otpInput.clearInput();
        this.error = e.response.data.error
      })
    }
  }
}
</script>

<style scoped>

  .login { padding: 60px; display: flex; align-items: center; justify-content: space-between; flex-direction: column; height: calc(100vh - 100px); }
  .login h1 { font-size: 42px; font-weight: 600; line-height: 1; text-transform: uppercase; margin-bottom: 36px; display: flex; flex-direction: column; text-align: center }
  .login h1 span { font-size: 28px; color: var(--orange) }

  .vue-tel-input { border: none; border-bottom: 1px solid var(--border); }
  .vue-tel-input:focus-within { box-shadow: none; }
  
  .opt { margin: auto; }
  
  /*input { background: none; border: none; }*/
  
  p { color: var(--text-primary); text-align: center; margin-bottom: 24px; font-weight: 300; line-height: 1.3; }
  
  button { margin-top: 36px; }

  svg.logo { fill: var(--orange) }

  .nameForm input { width: 100%; margin-bottom: 16px; }
  
  .error { color: var(--rouge); text-align: center; position: relative; top: -16px }

</style>